import { useState, useMemo, useLayoutEffect } from 'react';
import { isEmpty, compact } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useMeasure } from 'react-use';
import DefImage from 'next/image';
import { useRouter } from 'next/router';
import { EventDetailsQuery } from '@/apollo/operations';
import { Button, ButtonProps } from '@/components/buttons';
import { ItemStatus } from '@/components/styled';
import { Text as DefText, TextProps } from '@/components/texts';
import { useModals } from '@/providers';
import { resetListStyles } from '@/styles';
import dayjs from '@/utils/dayjs';
import { getPrice } from '@/utils/helpers';
import { TranslationKeys } from '@/utils/i18n';
import { Paper } from '@mui/material';
import { styled, css } from '@mui/material/styles';

export type EventDetailsProps = {
  event: Event;
  managerVenueId?: string;
};

const EventDetails = ({ event, managerVenueId }: EventDetailsProps) => {
  const { t } = useTranslation();
  const { push } = useRouter();
  const { dispatch } = useModals<
    | 'userVenueEventFinancialOverview'
    | 'userVenueAddEditEvent'
    | 'userVenueAddEditTournament'
    | 'sellPlayerShare'
    | 'buyTournamentTicketConfirm'
  >();
  const [ref, { height }] = useMeasure();
  const [imageWrapperWidth, setImageWrapperWidth] = useState<number | null>(
    null
  );

  const infoItems = useMemo(() => getInfoItems(event), [event]);

  const onHeaderControlClick = (type: HeaderButtons) => {
    switch (type) {
      case HeaderButtons.FINANCIAL_OVERVIEW:
        dispatch({
          type: 'setModalContent',
          payload: {
            name: 'userVenueEventFinancialOverview',
            data: {
              eventId: event.id,
            },
          },
        });
        break;
      case HeaderButtons.EDIT_EVENT:
        dispatch({
          type: 'setModalContent',
          payload: {
            name: 'userVenueAddEditEvent',
            data: {
              eventId: event.id,
            },
          },
        });
        break;
      case HeaderButtons.CREATE_TOURNAMENT:
        if (!managerVenueId) {
          throw new Error('There is no managerVenueId for tournament creation');
        }
        dispatch({
          type: 'setModalContent',
          payload: {
            name: 'userVenueAddEditTournament',
            data: {
              venueId: managerVenueId,
              eventId: event.id,
            },
          },
        });
        break;
      default:
        throw new Error('No such button type');
    }
  };

  useLayoutEffect(() => {
    setImageWrapperWidth(height);
  }, [height]);

  return (
    <>
      <Header>
        {/*
         //@ts-ignore */}
        <HeaderImageWrapper ref={ref} $width={imageWrapperWidth}>
          {!!event.image && (
            <HeaderImage
              width={134}
              height={134}
              src={event.image}
              alt={event.name}
            ></HeaderImage>
          )}
        </HeaderImageWrapper>
        <HeaderContent>
          <HeaderContentName>{event.name}</HeaderContentName>
          <HeaderContentLocation>{event.location}</HeaderContentLocation>
          {infoItems.map(([label, value]) => (
            <HeaderContentText key={label}>
              {t(label)}: {value}
            </HeaderContentText>
          ))}
        </HeaderContent>
        <HeaderControls>
          {managerVenueId && headerButtons.map(([type, { label, ...props }]) => (
            <li key={type}>
              <Button onClick={() => onHeaderControlClick(type)} {...props}>
                {t(label)}
              </Button>
            </li>
          ))}
        </HeaderControls>
      </Header>
      <Tournaments>
        <TournamentsTitle
          component={'h1'}
          variant={'display-small'}
          weight={'semi-bold'}
        >
          {t('EVENT_DETAILS__listTitle')}
        </TournamentsTitle>
        {isEmpty(event.tournaments) ? (
          <DefText align={'center'}>{t('EVENT_DETAILS__emptyItems')}</DefText>
        ) : (
          <TournamentsList>
            {event.tournaments.map((tournament) => (
              <TournamentsListItem key={tournament.id}>
                <TournamentsListItemName>
                  {tournament.name}
                </TournamentsListItemName>
                <TournamentsListItemDetails>
                  <span>
                    {t('EVENT_DETAILS__listItemBuyIn')}:
                    {getPrice(tournament.buyIn)}
                  </span>{' '}
                  | {tournament.type}
                  {tournament.guaranteed > 0 &&
                    `| ${t('EVENT_DETAILS__listItemGTD')} ${getPrice(
                      tournament.guaranteed
                    )}`}{' '}
                </TournamentsListItemDetails>
                {tournament.location && (
                  <TournamentsListItemLocation>
                    {t('EVENT_DETAILS__listLocation')}: {tournament.location}
                  </TournamentsListItemLocation>
                )}
                <TournamentsListItemDate>
                  {dayjs(tournament.date as string).format('D MMMM HH:mm:ss')}
                </TournamentsListItemDate>
                <TournamentListItemControls $rows={tournament.location ? 4 : 3}>
                  {managerVenueId ? (
                    <>
                      <Status>{tournament.status}</Status>
                      <TournamentsListItemButton
                        onClick={() => {
                          push(`/user-venue-tournament/${tournament.slug}`);
                        }}
                      >
                        {t('EVENT_DETAILS__listItemManageButton')}
                      </TournamentsListItemButton>
                    </>
                  ) : (
                    <>
                      <TournamentsListItemButton
                        onClick={() => {
                          dispatch({
                            type: 'setModalContent',
                            payload: {
                              name: 'sellPlayerShare',
                              data: {
                                tournamentId: tournament.id,
                                sharesMax: tournament.sharesMax,
                                bulletsMax: tournament.bulletsMax,
                                buyIn: tournament.buyIn,
                              },
                            },
                          });
                        }}
                      >
                        {t('EVENT_DETAILS__listItemSellButton')}
                      </TournamentsListItemButton>
                      { tournament.ticketSalesActive && (
                        <TournamentsListItemButton
                          onClick={() => {
                            dispatch({
                              type: 'setModalContent',
                              payload: {
                                name: 'buyTournamentTicketConfirm',
                                data: {
                                  tournamentId: tournament.id,
                                  eventName: event.name,
                                  tournamentName: tournament.name,
                                  startTime: tournament.date,
                                  buyIn: tournament.buyIn,
                                  bullets: 1,
                                  localCurrencySymbol:
                                  tournament.local_currency.symbol,
                                  localCurrencyRate:
                                  tournament.local_currency.rate,
                                },
                              },
                            });
                          }}
                        >
                          {t('EVENT_DETAILS__listItemBuyTicketButton')}
                        </TournamentsListItemButton>)
                      }
                    </>
                  )}
                </TournamentListItemControls>
              </TournamentsListItem>
            ))}
          </TournamentsList>
        )}
      </Tournaments>
    </>
  );
};

type Event = EventDetailsQuery['events'][number];

const getInfoItems = (event: Event) => {
  const datesStr = [event.dateFrom, event.dateTill]
    .map((val) => dayjs(val).format('D MMM'))
    .join(' - ');

  const dates = ['SELL_SHARE_EVENT__dates', datesStr] as const;

  let playerShares = null;

  if (
    !isEmpty(event.tournaments) &&
    event.tournaments.every((tournament) => 'player_shares' in tournament)
  ) {
    const playerSharesAmount = event.tournaments
      .reduce((acc, { player_shares }) => {
        acc += player_shares!.length;
        return acc;
      }, 0)
      .toString();

    playerShares = [
      'SELL_SHARE_EVENT__playerShares',
      playerSharesAmount,
    ] as const;
  }

  return compact([dates, playerShares]);
};

const Header = styled('div')`
  display: flex;
  margin-top: 48px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

const HeaderImageWrapper = styled('div')<{
  $width: number | null;
}>`
  ${({ $width }) =>
    !!$width &&
    css`
      width: ${$width}px;
    `};
  max-width: 50%;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  flex-shrink: 0;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 30%;
    aspect-ratio: 1;
    align-self: flex-start;
  }
`;

const HeaderImage = styled(DefImage)`
  object-fit: contain;
  object-position: center;
  margin: 8px;
`;

const HeaderContent = styled('div')`
  flex: 1 1 auto;
`;

const HeaderContentName = styled((props: TextProps) => (
  <DefText component={'h2'} variant={'display-medium'} {...props} />
))`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 1.5rem;
  }
`;

const HeaderContentLocation = styled((props: TextProps) => (
  <DefText variant={'title-large'} color={'primary'} {...props} />
))`
  margin-bottom: 16px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 1.1rem;
  }
`;

const HeaderContentText = styled((props: TextProps) => (
  <DefText variant={'title-large'} {...props} />
))`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 1.1rem;
  }
`;

const HeaderControls = styled('ul')`
  ${resetListStyles};
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    justify-content: unset;
    margin-top: 16px;
    li {
      flex: 1 1 50%;
      .MuiButton-root {
        width: 100%;
      }
    }
  }
`;

const Tournaments = styled('section')`
  margin-top: 50px;
`;

const TournamentsTitle = styled(DefText)`
  margin-bottom: 25px;
`;

const TournamentsList = styled('ul')`
  ${resetListStyles};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    grid-template-columns: auto;
  }
`;

const TournamentsListItem = styled(Paper)`
  padding: 16px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  grid-gap: 8px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: 100%;
  }
`;

TournamentsListItem.defaultProps = {
  // @ts-ignore
  component: 'li',
};

const TournamentsListItemName = styled((props: TextProps) => (
  <DefText component={'h2'} variant={'title-large'} {...props} />
))`
  grid-column: 1;
`;

const TournamentsListItemLocation = styled((props: TextProps) => (
  <DefText component={'h2'} variant={'title-small'} {...props} />
))`
  grid-column: 1;
`;

const TournamentsListItemDetails = styled((props: TextProps) => (
  <DefText variant={'title-small'} color={'primary'} {...props} />
))`
  grid-column: 1;

  span {
    color: ${({ theme }) => theme.getColor('bitterSweet')};
  }
`;

const TournamentsListItemDate = styled((props: TextProps) => (
  <DefText variant={'title-small'} {...props} />
))`
  grid-column: 1;
`;

const TournamentListItemControls = styled('div')<{
  $rows: number;
}>`
  grid-column: 2;
  grid-row: 1 / ${({ $rows }) => $rows + 1};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-column: 1;
    grid-row: 4;
  }
`;

const Status = styled(ItemStatus)``;

const TournamentsListItemButton = styled(Button)`
  display: block;
`;

enum HeaderButtons {
  FINANCIAL_OVERVIEW,
  EDIT_EVENT,
  CREATE_TOURNAMENT,
}

const headerButtonsMap: ReadonlyMap<
  HeaderButtons,
  { label: TranslationKeys } & ButtonProps
> = new Map([
  [
    HeaderButtons.FINANCIAL_OVERVIEW,
    {
      label: 'EVENT_DETAILS__financialOverviewEventButton',
    },
  ],
  [
    HeaderButtons.EDIT_EVENT,
    {
      label: 'EVENT_DETAILS__editEventButton',
      variant: 'outlined',
    },
  ],
  [
    HeaderButtons.CREATE_TOURNAMENT,
    {
      label: 'EVENT_DETAILS__createTournamentButton',
    },
  ],
]);

const headerButtons = Array.from(headerButtonsMap);

export { EventDetails };
